<template>
  <App
    title="权益"
    left-arrow
  >
    <Card
      v-for="$e in dataList"
      :key="$e.code"
    >
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{$e.benefitName}}</h3>
        </div>
        <div class="description">
          <h4 class="accountMobile">{{$e.benefitDesc}}</h4>
        </div>
      </template>
    </Card>
    <div
      class="noData"
      v-if="dataList.length <= 0"
    >暂无数据</div>
  </App>
</template>

<script>
import Card from '_c/card'

export default {
  name: 'benefit',
  components: { Card },
  data () {
    return {
      tabActive: 'feature',
      dataList: []
    }
  },
  methods: {
  },
  created () {
    this.dataList = this.$route.query.benefitData || []
  }
}
</script>
<style lang="less" scoped>
.card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name {
    width: 500px;
    margin-right: 20px;
  }

  .tag {
    display: inline-block;
    padding: 0 10px;
    color: @blue;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
  }
}
.noData {
  color: #969799;
  font-size: 14px;
  line-height: 100px;
  text-align: center;
}
</style>
